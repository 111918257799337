import React, { MouseEvent } from 'react';
import { Wrapper, Logos, EnlargedLogo } from './styles';
import RyanBros from 'assets/logos/ryanbros.png';
import HolyCraft from 'assets/logos/holycraft.png';
import { Grid } from '@material-ui/core';

export const SocialProof: React.FC = () => (
  <Wrapper>
    <h2>What's the consumer experience?</h2>
    <h3>Try it out! Get a gift card from one of these amazing businesses:</h3>
    
    <Grid container xs={12}>
      <Grid item xs={12} md={6}>
        <a href="https://app.paneau.io/giftcards/Ryan_bros_coffee">
          <EnlargedLogo src={RyanBros} />
        </a>
      </Grid>
      <Grid item xs={12} md={6}>
        <a href="https://app.paneau.io/giftcards/HolyCraft_Brewery_Taproom">
          <EnlargedLogo src={HolyCraft} />
        </a>
      </Grid>
    </Grid>
  </Wrapper>
);

export default SocialProof;
