import styled from 'styled-components';
import { Container } from 'components/common/Container';

export const Paper = styled.div`
  border-radius: 4px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  margin: 1rem;
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 280px;
  max-width: 500px;

  @media (min-width: 973px) {
    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: 973px) {
    &:first-child {
      margin-left: 0;
    }
  }

  p {
    margin-bottom: 0;
    padding-top: 1.45rem;
  }

  svg {
    max-height: 124px;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 972px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Wrapper = styled(Container)`
  text-align: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 124px;
  margin: 0 auto;
`;
