import React from 'react';
import { Wrapper, Paper, StepsContainer, IconWrapper } from './styles';
import Account from 'assets/icons/account.inline.svg';
import GiftCard from 'assets/icons/gift-card.inline.svg';
import Network from 'assets/icons/network.inline.svg';

export const Steps: React.FC = () => {
  return (
    <Wrapper>
      <h2>Creating a gift card is easy.</h2>
      <p>
        We're here to support you and your business, so signing up and using our
        gift card platform is free. We normally charge a 3% transaction fee to
        cover our operating costs, but we're currently waiving all fees in light
        of the COVID-19 pandemic. Sales are deposited directly into your Stripe
        account.
      </p>
      <StepsContainer>
        <Paper>
          <h3>Create an Account</h3>
          <IconWrapper>
            <Account width="50%" />
          </IconWrapper>
          <p>
            Use the sign-up form above and follow our guided account setup flow.
            We use Stripe to securely process all payments.
          </p>
        </Paper>
        <Paper>
          <h3>Create a Gift Card</h3>
          <IconWrapper>
            <GiftCard width="50%" />
          </IconWrapper>
          <p>
            Use our simple gift card creator designed to integrate with Apple
            Wallet.
          </p>
        </Paper>
        <Paper>
          <h3>Share Your Gift Card With Customers</h3>
          <IconWrapper>
            <Network width="50%" />
          </IconWrapper>
          <p>
            Post a shareable link to your gift card page on social media sites
            such as Facebook, Instagram, and Nextdoor.
          </p>
        </Paper>
      </StepsContainer>
    </Wrapper>
  );
};
