import React from 'react';
import { Header } from 'components/theme';
import { Layout, SEO } from 'components/common';
import { Intro, Laptop, Steps, Integrate, Redeem, Press } from 'components/giftcards';
import { SocialProof } from 'components/giftcards/SocialProof';

export default () => (
  <Layout>
    <SEO type="giftcards" />
    <Header product="giftcards" />
    <Intro />
    <Press />
    <Steps />
    <Laptop />
    <Integrate />
    <Redeem />
    <SocialProof />
  </Layout>
);
