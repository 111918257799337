import React from 'react';
import IntegratesWellIllustration from 'assets/illustrations/integrates-well.inline.svg';
import { Wrapper, Content } from './styles';

export const Integrate: React.FC = () => {
  return (
    <Wrapper>
      <Content>
        <h2>Integrate Our Gift Cards With Any Payment Platform</h2>
        <p>
          Our platform operates independently all payment services. Regardless
          of whether your business runs on Square, PayPal, Intuit, or Grandpa's
          trusty cash register, we've got you covered.
        </p>
      </Content>
      <IntegratesWellIllustration height="auto" />
    </Wrapper>
  );
};
