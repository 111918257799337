import styled from 'styled-components';
import { Container } from 'components/common/Container';

export const Wrapper = styled(Container)`
  text-align: center;
  display: flex;
  align-items: center;

  @media (max-width: 685px) {
    flex-direction: column;

    svg {
      max-width: 80%;
      height: auto;
    }
  }
`;

export const Content = styled.div`
  padding: 1rem;
  flex-grow: 1;
  padding-right: 4rem;

  p {
    margin-bottom: 0;
  }

  @media (max-width: 685px) {
    padding-right: 1rem;
  }
`;
