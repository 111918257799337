import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { Background, Wrapper } from './styles';
import Top from 'assets/background/whiteendtop.inline.svg';
import Bottom from 'assets/background/whiteendbottom.inline.svg';

export const Laptop: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/laptop-dashboard.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Background>
      <Top width="100%" height="auto" style={{ verticalAlign: 'top' }} />
      <Wrapper>
        <Image fluid={data.file.childImageSharp.fluid} />
      </Wrapper>
      <Bottom width="100%" height="auto" style={{ verticalAlign: 'bottom' }} />
    </Background>
  );
};
