import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Wrapper, Content, StyledImage } from './styles';

export const Redeem: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/iphone-charge.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <StyledImage fluid={data.file.childImageSharp.fluid} />
      <Content>
        <h2>Easily Charge Gift Cards From Your Phone</h2>
        <p>
          Each gift cards contains a QR code that can easily be scanned and a
          short code that can be manually entered on our site. To charge a gift
          card, scan the QR code using or enter the short code, and simply enter
          the amount you'd like to be charged to the card.
        </p>
      </Content>
    </Wrapper>
  );
};
