import React from 'react';
import { Container } from 'components/common';
import {
  IntroWrapper,
  Content,
  SignUpButton,
  Link,
  DemoLink,
  ReferralSection,
} from './styles';
import { useStaticQuery, graphql } from 'gatsby';
import SocialDistance from 'assets/illustrations/social-distance.inline.svg';
import Helmet from 'react-helmet';

export const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "background/defocused-image-of-lights.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <IntroWrapper as={Container}>
      <Helmet>
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
        ></script>
      </Helmet>
      <Content>
        <h1>Sell and redeem gift cards easily—100% free.</h1>
        <SocialDistance width="100%" height="auto" />
        <h1>Generate cash flow during the COVID-19 pandemic.</h1>
      </Content>
      <Link href={`${process.env.GATSBY_GIFTCARD_BASE_URL}/signup`}>
        <SignUpButton variant="contained" color="secondary" size="large">
          Sign Up for FREE
        </SignUpButton>
      </Link>
      <DemoLink href={`${process.env.GATSBY_GIFTCARD_BASE_URL}/signup`}>
        or request a demo
      </DemoLink>
      <ReferralSection>
        Know of a local business that currently doesn't offer gift cards & you'd
        like to see on our platform? Fill out{' '}
        <a href="/giftcards/refer">this form</a> and we'll reach out to them.
      </ReferralSection>
    </IntroWrapper>
  );
};
