import styled from 'styled-components';
import { Container } from 'components/common/Container';
import Image from 'gatsby-image';

export const Wrapper = styled(Container)`
  text-align: center;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;

  @media (max-width: 685px) {
    flex-direction: column;
    flex-flow: column-reverse;

    svg {
      max-width: 80%;
      height: auto;
    }
  }
`;

export const Content = styled.div`
  padding: 1rem;
  flex-grow: 1;
  padding-left: 4rem;
  margin: 4rem 0;

  p {
    margin-bottom: 0;
  }

  @media (max-width: 685px) {
    padding-left: 1rem;
    margin-bottom: 0;
  }
`;

export const StyledImage = styled(Image)`
  width: 33%;
  min-width: 200px;
  max-width: 250px;
  transform: rotate(12deg);
  margin: 2rem 0 2rem 4rem;

  @media (max-width: 685px) {
    margin: 2rem 0 4rem 0;
    transform: none;
    width: 50%;
    min-width: unset;
    max-width: 200px;
  }
`;
