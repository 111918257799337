import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;

  h1 {
    margin-bottom: 1rem;

    &:last-child {
      margin-top: 2rem;
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  padding: 2rem 0;
  text-align: center;

  @media (max-width: 680px) {
    padding-top: 1rem;
  }

  svg {
    max-width: 500px;
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;

export const SwitchFormTypeLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  margin: 0 auto;
`;

export const SignInText = styled.p`
  margin: 1rem 0 0 0;
`;

export const SignInLink = styled.a`
  text-decoration: underline;
`;

export const CalendlyWidget = styled.div`
  min-width: 320px;
  height: 630px;
`;

export const Link = styled.a`
  &,
  &:hover,
  &:visited,
  &:active {
    text-decoration: none;
  }
`;

export const SignUpButton = styled(Button)`
  font-size: 24px !important;
`;

export const DemoLink = styled.a`
  margin-top: 1rem;
`;

export const ReferralSection = styled.div`
  padding-top: 2rem;
  text-align: center;
`;
