import React, { MouseEvent } from 'react';
import { Wrapper, Logos, EnlargedLogo } from './styles';
import SDUT from 'assets/logos/sdut.jpeg';
import GMSD from 'assets/logos/gmsd.jpeg';
import SDBJ from 'assets/logos/sdbj.jpeg';
import SBNP from 'assets/logos/sbnp.jpeg';
import { Grid } from '@material-ui/core';

export const Press: React.FC = () => (
  <Wrapper>
    <h2>As seen on</h2>
    {/* <h3>Try it out! Get a gift card from one of these amazing businesses:</h3> */}
    <Grid container xs={12}>
      <Grid item xs={12} md={6} lg={3}>
        <a href="https://www.sandiegouniontribune.com/business/technology/story/2020-04-24/this-25-year-old-san-diegan-just-created-an-easy-way-to-buy-online-gift-cards-for-struggling-local-restaurants">
          <EnlargedLogo src={SDUT} />
        </a>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <a href="https://www.kusi.com/san-diegan-develops-way-to-support-local-restaurants-with-gift-cards/">
          <EnlargedLogo src={GMSD} />
        </a>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <a href="http://sdbj.com/news/2020/may/14/advertising-startup-helps-local-companies-survive-/">
          <EnlargedLogo src={SDBJ} />
        </a>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <a href="https://newspress.com/ucsb-alums-startup-paneau-builds-gift-card-platform-for-small-businesses-to-use-during-coronavirus-crisis/">
          <EnlargedLogo src={SBNP} />
        </a>
      </Grid>
    </Grid>
  </Wrapper>
);

export default Press;
